import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Testimonial from '../components/testimonial'
import AnimatedHr from '../components/animatedHr'

import aboutImage from '../images/nova-electronics-experience.png'
import mascot from '../images/nova-electronics-mascot.png'
import serviceOne from '../images/PCB-repairs.png'
import serviceTwo from '../images/catering-equipment-repairs.png'
import serviceThree from '../images/industrial-equipment-repair.png'
import serviceFour from '../images/ferric-meters.png'
import serviceFive from '../images/other-services.png'

import phoneIcon from '../images/phone-icon.svg'
import emailIcon from '../images/email-icon.svg'

const IndexPage = () => (
  <Layout>
    <div className="headerImage home">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>
              Welcome To <br />
              Nova Electronics
            </h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>

            <p>
              Your Sheffield Experts in repairing electronic devices from
              catering equipment to laptops.
            </p>
          </div>
        </div>
        <div
          className="mascot"
          style={{
            backgroundImage: 'url(' + mascot + ')',
          }}
        />
        <div className="headerButtonContainer">
          <div className="headerButton">
            <span
              className="headerButtonIcon"
              style={{
                backgroundImage: 'url(' + phoneIcon + ')',
                backgroundColor: '#1183db',
              }}
            />
            <div
              style={{
                padding: '5px 20px 5px 15px',
                alignItems: 'center',
              }}
            >
              <p style={{ marginBottom: 0 }}>Call Us Today</p>
              <a href="tel:0114 244 7257">0114 244 7257</a>
            </div>
          </div>
          <div className="headerButton">
            <span
              className="headerButtonIcon"
              style={{
                backgroundImage: 'url(' + emailIcon + ')',
                backgroundColor: '#f76d0f',
              }}
            />
            <div style={{ padding: '5px 20px 5px 15px', alignItems: 'center' }}>
              <p style={{ marginBottom: 0 }}>Email Us</p>
              <a href="mailto:repairs@nova-electronics.co.uk">
                repairs@nova-electronics.co.uk
              </a>
            </div>
          </div>
          <div className="headerButton">
            <div style={{ padding: '5px 20px 5px 15px', alignItems: 'center' }}>
              <p style={{ marginBottom: 0 }}>Opening Times</p>
              Tuesday to Friday 9am to 4.30 pm.
            </div>
          </div>
        </div>
      </div>
    </div>
    <section>
      <div className="container">
        <div className="row">
          <div className="columns twelve">
            <h2>
              About Us
              <AnimatedHr />
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="columns six">
            <div
              style={{
                backgroundImage: 'url(' + aboutImage + ')',
                backgroundSize: 'cover',
                height: '500px',
              }}
            />
          </div>
          <div className="columns six">
            <p>
              We have over 40 years experience and a thorough practical
              knowledge of electronics gained at the workbench.
            </p>
            <p>
              Our pride comes from saving you money by enabling you to repair
              instead of replace, giving your familiar device a new lease of
              life. We are committed to helping local businesses do well and to
              providing a personal, professional service to all our customers.
            </p>
            <p>
              Faults with electronic devices can, in many cases, be easily fixed
              and save you money. However, if this is not possible, we will
              discuss your options with you.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="columns twelve">
            <h2>
              Our Services
              <AnimatedHr />
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="columns four">
            <Link to="/pcb-repairs">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceOne + ')' }}
                />
                <p>
                  <br /> PCB Repairs
                </p>
              </div>
            </Link>
          </div>
          <div className="columns four">
            <Link to="/catering-repairs">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceTwo + ')' }}
                />

                <p>
                  <br /> Catering Repairs
                </p>
              </div>
            </Link>
          </div>
          <div className="columns four">
            <Link to="/industrial-repairs">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceThree + ')' }}
                />
                <p>
                  <br /> Industrial Repairs
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="columns one">
            <p />
          </div>
          <div className="columns five">
            <Link to="/about-us">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceFour + ')' }}
                />
                <p>
                  <br /> About Us
                </p>
              </div>
            </Link>
          </div>
          <div className="columns five">
            <Link to="/ferric-meter">
              <div className="serviceBox">
                <div
                  className="serviceBoxImage"
                  style={{ backgroundImage: 'url(' + serviceFive + ')' }}
                />
                <p>
                  <br /> Ferric Meters
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>

    {/* <section>
      <div className="container">
        <div className="row">
          <div className="columns twelve">
            <Testimonial />
          </div>
        </div>
      </div>
    </section> */}
  </Layout>
)

export default IndexPage
